import { useRouter } from 'next/router'
import { useEffect } from 'react'

export function useAppRedirect() {
  const router = useRouter()

  useEffect(() => {
    if (router.query.mobileRedirect) {
      if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        window.location.href = router.query.mobileRedirect as string
      }
    }
  }, [router.query])
}
