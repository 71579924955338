import type { StyleFunctionProps } from '@chakra-ui/theme-tools'

import { mode, transparentize } from '@chakra-ui/theme-tools'

const baseStyle = {
  table: {
    bg: 'bg-surface',
    whiteSpace: 'nowrap',
  },
  th: {
    borderTopWidth: '1px',
    fontWeight: 'medium',
    letterSpacing: 'normal',
    textTransform: 'normal',
    whiteSpace: 'nowrap',
  },
}

const variants = {
  simple: (props: StyleFunctionProps) => ({
    th: {
      bg: mode('gray.50', transparentize('gray.700', 0.4)(props.theme))(props),
      color: 'muted',
    },
  }),
  striped: (props: StyleFunctionProps) => ({
    tbody: {
      tr: {
        '&:last-of-type': {
          'th, td': {
            borderBottomWidth: '1px',
          },
        },
        '&:nth-of-type(odd)': {
          'td': {
            bg: mode(
              'gray.50',
              transparentize('gray.700', 0.4)(props.theme),
            )(props),
          },
          'th, td': {
            borderBottomWidth: '0px',
          },
        },
        'th,td': {
          borderWidth: '0px',
        },
      },
    },
    th: {
      borderBottomWidth: '0px',
      color: 'muted',
    },
    thead: {
      'th,td': {
        borderWidth: '0px',
      },
    },
  }),
}

const sizes = {
  md: {
    td: {
      fontSize: 'sm',
    },
    th: {
      lineHeight: '1.25rem',
    },
  },
}

const table = {
  baseStyle,
  sizes,
  variants,
}
export default table
