import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'
/*
 * Usage:
 *   const { alert, confirm, prompt } = useModals()
 *   alert("Hey!") // awaitable too
 *   if (await confirm("Are you sure?")) ...
 *   const result = await prompt("Enter a URL", "http://")
 */
import type { ReactNode } from 'react'

import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react'

// TODO: Select field contents when a prompt() loads

enum ModalType {
  Alert,
  Confirm,
  Prompt,
}

export type ModalResponse =
  | { success: boolean }
  | { success: boolean; value: string }
export interface Modals {
  alert: (message: string) => Promise<ModalResponse>
  confirm: (message: string) => Promise<ModalResponse>
  prompt: (message: string, defaultValue?: string) => Promise<ModalResponse>
}

const defaultContext: Modals = {
  alert() {
    throw new Error('<ModalsProvider> is missing')
  },
  confirm() {
    throw new Error('<ModalsProvider> is missing')
  },
  prompt() {
    throw new Error('<ModalsProvider> is missing')
  },
}

const Context = createContext<Modals>(defaultContext)

interface AnyEvent {
  preventDefault(): void
}

export const ModalsProvider = ({ children }: { children: ReactNode }) => {
  const [modal, setModal] = useState<null | ReactNode>(null)
  const input = useRef<HTMLInputElement>(null)
  const ok = useRef<HTMLButtonElement>(null)

  const createOpener = useCallback(
    (type: ModalType) =>
      (message: string, defaultValue = '') =>
        new Promise<ModalResponse>((resolve) => {
          const handleClose = (e?: AnyEvent) => {
            e?.preventDefault()
            setModal(null)
            resolve({ success: false })
          }

          const handleOK = (e?: AnyEvent) => {
            e?.preventDefault()
            setModal(null)
            if (type === ModalType.Prompt) {
              resolve({ success: true, value: input.current?.value })
            } else {
              resolve({ success: true })
            }
          }

          setModal(
            <form onSubmit={handleOK}>
              <Modal
                initialFocusRef={type === ModalType.Prompt ? input : ok}
                isOpen={true}
                onClose={handleClose}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalBody mt={5}>
                    <Stack spacing={5}>
                      <Text> {message}</Text>
                      {type === ModalType.Prompt && (
                        <Input defaultValue={defaultValue} ref={input} />
                      )}
                    </Stack>
                  </ModalBody>
                  <ModalFooter>
                    {type !== ModalType.Alert && (
                      <Button mr={3} onClick={handleClose} variant="ghost">
                        Cancel
                      </Button>
                    )}
                    <Button
                      colorScheme="brand"
                      onClick={handleOK}
                      ref={ok}
                      type="submit"
                    >
                      OK
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </form>,
          )
        }),
    [],
  )

  return (
    <Context.Provider
      value={{
        alert: createOpener(ModalType.Alert),
        confirm: createOpener(ModalType.Confirm),
        prompt: createOpener(ModalType.Prompt),
      }}
    >
      {children}
      {modal}
    </Context.Provider>
  )
}

export const useModals = () => useContext(Context)
