import type { RouterOutput } from '@/lib/trpcClient'
import type { IdNameRecord } from 'p-data/src/services/idNameRecord'
import type { Institution } from 'p-data/src/services/institution'
import type { SafeParseSuccess } from 'zod'

import { COUNTRIES } from 'p-data/src/lib/countries'
import { OCCUPATIONS } from 'p-data/src/lib/occupations'
import { idNameRecordSchema } from 'p-data/src/services/idNameRecord'
import { institutionSchema } from 'p-data/src/services/institution'

export const normalizeProfileValues = (
  profile?: RouterOutput['profiles']['currentUser'],
) => {
  if (!profile) {
    return undefined
  }

  return {
    country: COUNTRIES.find((c) => c.id === profile.country),
    email: profile.email,
    firstName: profile.firstName ?? '',
    howDidYouHearAboutUs: profile.howDidYouHearAboutUs ?? '',
    institution: (
      institutionSchema.safeParse(
        profile.institution,
      ) as SafeParseSuccess<Institution>
    ).data,
    lastName: profile.lastName ?? '',
    npi: profile.proId || undefined,
    occupation: OCCUPATIONS.find((occ) => occ.id === profile.occupation),
    occupationOther: profile.occupationOther ?? undefined,
    specialties:
      (
        idNameRecordSchema
          .array()
          .safeParse(profile.specialties) as SafeParseSuccess<IdNameRecord[]>
      ).data ?? [],
  }
}
