const tokens = {
  colors: {
    'accent': {
      _dark: 'brand.200',
      default: 'brand.500',
    },
    'bg-accent': 'brand.600',
    'bg-accent-muted': 'brand.400',
    'bg-accent-subtle': 'brand.500',
    'bg-canvas': {
      _dark: 'gray.900',
      default: 'gray.50',
    },
    'bg-muted': {
      _dark: 'gray.600',
      default: 'gray.100',
    },
    'bg-subtle': {
      _dark: 'gray.700',
      default: 'gray.50',
    },
    'bg-surface': {
      _dark: 'gray.800',
      default: 'white',
    },
    'border': {
      _dark: 'gray.700',
      default: 'gray.200',
    },
    'default': {
      _dark: 'white',
      default: 'gray.900',
    },
    'emphasized': {
      _dark: 'gray.100',
      default: 'gray.700',
    },
    'error': {
      _dark: 'red.200',
      default: 'red.600',
    },
    'inverted': {
      _dark: 'gray.900',
      default: 'white',
    },
    'muted': {
      _dark: 'gray.300',
      default: 'gray.600',
    },
    'on-accent': 'white',
    'on-accent-muted': 'brand.50',
    'on-accent-subtle': 'brand.100',
    'subtle': {
      _dark: 'gray.400',
      default: 'gray.500',
    },
    'success': {
      _dark: 'green.200',
      default: 'green.600',
    },
  },
}

export default tokens
