import type { ReactNode } from 'react'

import { mixpanel } from '@/modules/mixpanel'
import { setSentrySessionTracking } from '@/utils/setSentrySessionTracking'
import { useProfile } from '@/utils/useProfile'
import posthog from 'posthog-js'
import { useEffect } from 'react'

export function InitializeUser(props: { children: ReactNode }) {
  const { profile } = useProfile()
  useEffect(() => {
    // intialize Sentry with user info if available
    async function initializeUser() {
      if (profile) {
        mixpanel.identify(profile.distinctId)
        setSentrySessionTracking(profile)
        posthog.identify(profile.distinctId, {
          email: profile.email,
        })
      }
    }

    void initializeUser()
  }, [profile])

  return props.children
}
