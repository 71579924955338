import type { ReactNode } from 'react'

import { mixpanel } from '@/modules/mixpanel'
import { useProfile } from '@/utils/useProfile'
import { useUserIsInCookieBannerRegion } from '@/utils/useUserIsInCookieBannerRegion'
import { useRouter } from 'next/router'
import Script from 'next/script'
import posthog from 'posthog-js'
import { useEffect, useState } from 'react'

import { CookieBanner } from './CookieBanner'

const cookieKey = 'cookie-acceptance'
const isDev = process.env.NODE_ENV === 'development'
const comscoreClientId = '39397382'

export function AdvertisingAndAnalytics(props: { children: ReactNode }) {
  const [doNotTrack, setDoNotTrack] = useState(true)
  const [showCookieBanner, setShowCookieBanner] = useState(false)
  const router = useRouter()

  const profileQuery = useProfile()
  const userIsInCookieBannerRegion = useUserIsInCookieBannerRegion({
    enabled: profileQuery.isLoggedOut,
  })

  function setCookieDecision(enabled: boolean) {
    localStorage.setItem(cookieKey, enabled ? '1' : '0')
    setShowCookieBanner(false)
  }

  useEffect(() => {
    const savedCookieDecision = localStorage.getItem(cookieKey)
    if (
      savedCookieDecision === '1' ||
      profileQuery.isLoggedIn ||
      userIsInCookieBannerRegion === false
    ) {
      setDoNotTrack(false)
    } else if (
      userIsInCookieBannerRegion === true &&
      savedCookieDecision === null
    ) {
      mixpanel.opt_out_tracking()
      posthog.opt_out_capturing()
      setShowCookieBanner(true)
    } else if (savedCookieDecision === '0') {
      mixpanel.opt_out_tracking()
      posthog.opt_out_capturing()
    }
  }, [profileQuery.isLoggedIn, userIsInCookieBannerRegion])

  useEffect(() => {
    if (doNotTrack) return
    function trackRoute() {
      if (!doNotTrack) {
        window.COMSCORE?.beacon({ c1: '2', c2: comscoreClientId, cs_ucfr: '1' })
        void fetch('/api/comscore')
      }
    }
    router.events.on('routeChangeComplete', trackRoute)
    return () => router.events.off('routeChangeComplete', trackRoute)
  }, [router, doNotTrack])

  return (
    <>
      {props.children}
      {doNotTrack === false && !isDev && (
        <Script id="comScore" strategy="afterInteractive">
          {`
              var _comscore = _comscore || [];
              _comscore.push({ c1: "2", c2: "${comscoreClientId}" ,cs_ucfr: "1",  options: { enableFirstPartyCookie: "true" } });
              (function() {
                var s = document.createElement("script"), el = document.getElementsByTagName("script")[0]; s.async = true;
                s.src = "https://sb.scorecardresearch.com/cs/39397382/beacon.js";
                el.parentNode.insertBefore(s, el);
                })();
            `}
        </Script>
      )}
      {showCookieBanner && <CookieBanner onDecide={setCookieDecision} />}
    </>
  )
}
