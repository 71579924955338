import { Flex, Link, Text } from '@chakra-ui/layout'
import { Button, useColorMode } from '@chakra-ui/react'
import NextLink from 'next/link'

export function CookieBanner(props: { onDecide: (accepted: boolean) => void }) {
  const isLight = useColorMode().colorMode === 'light'

  return (
    <Flex
      alignItems={'center'}
      bg={isLight ? 'gray.100' : 'blue.950'}
      borderTopColor={isLight ? 'gray.200' : 'blue.900'}
      borderTopWidth={1}
      bottom={0}
      flexWrap={'wrap'}
      gap={4}
      justifyContent={'center'}
      position="fixed"
      px={5}
      py={4}
      width={'100%'}
      zIndex={99}
    >
      <Text fontSize={[12, 14]} maxWidth={'860px'}>
        {`We and our partners use cookies to enhance your experience on our site, analyze site usage and personalize ads. You can withdraw your consent at any time, as set out in our`}{' '}
        <Link
          as={NextLink}
          color={isLight ? 'blue.600' : 'blue.200'}
          href="/privacy-policy"
        >
          Privacy Policy
        </Link>
        .
      </Text>
      <Flex flexGrow={[1, 0]} gap={2} justifyContent={'flex-end'}>
        <Button
          onClick={() => props.onDecide(true)}
          size="sm"
          variant="primary"
        >
          Accept All
        </Button>
        <Button
          onClick={() => props.onDecide(false)}
          size="sm"
          variant={'outline'}
        >
          Decline All
        </Button>
      </Flex>
    </Flex>
  )
}
