export const OCCUPATIONS = [
  {
    icon: 'profession-physician',
    id: 'physician',
    name: 'Physician',
  },
  { icon: 'profession-resident', id: 'resident', name: 'Resident Physician' },
  {
    icon: 'profession-other',
    id: 'physician-assistant',
    name: 'Physician Assistant',
  },
  {
    icon: 'profession-nurse',
    id: 'nurse',
    name: 'Nurse',
  },
  {
    icon: 'profession-nurse',
    id: 'nurse-practitioner',
    name: 'Nurse Practitioner',
  },
  {
    icon: 'profession-student',
    id: 'student',
    name: 'Medical / Nursing Student',
  },
  {
    icon: 'specialty-medical-biochemistry',
    id: 'pharmacist',
    name: 'Pharmacist',
  },
  {
    icon: 'profession-other',
    id: 'other',
    name: 'Other Healthcare Professional',
  },
] as const
