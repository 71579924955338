import { mixpanel } from '@/modules/mixpanel'
import { useEffect } from 'react'

export const useAdWordsReferralTracking = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const adParameters = {
      adposition: urlParams.get('adposition'),
      device: urlParams.get('device'),
      gclid: urlParams.get('gclid'),
      matchtype: urlParams.get('matchtype'),
      network: urlParams.get('network'),
      utm_campaign: urlParams.get('utm_campaign'),
      utm_content: urlParams.get('utm_content'),
      utm_medium: urlParams.get('utm_medium'),
      utm_source: urlParams.get('utm_source'),
      utm_term: urlParams.get('utm_term'),
    }

    const cleanedAdParameters = Object.fromEntries(
      Object.entries(adParameters).filter(([, value]) => value != null),
    )

    // Check if we have any parameters to log
    if (Object.keys(cleanedAdParameters).length > 0) {
      // Log the event to Mixpanel
      mixpanel.track('ad-words-referral', cleanedAdParameters)
    }
  }, [])
}
