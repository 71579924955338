const baseStyle = {
  track: {
    borderRadius: 'base',
  },
}

const variants = {
  'on-accent': {
    filledTrack: {
      bg: 'brand.50',
    },
    track: {
      bg: 'transparent',
    },
  },
  'solid': {
    track: {
      bg: 'bg-muted',
    },
  },
}

const defaultProps = {
  colorScheme: 'brand',
  variant: 'solid',
}

const progress = {
  baseStyle,
  defaultProps,
  variants,
}

export default progress
