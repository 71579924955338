export const STATES = [
  { country: 'US', state: { isoCode: 'AL', name: 'Alabama' } },
  { country: 'US', state: { isoCode: 'AK', name: 'Alaska' } },
  { country: 'US', state: { isoCode: 'AZ', name: 'Arizona' } },
  { country: 'US', state: { isoCode: 'AR', name: 'Arkansas' } },
  { country: 'US', state: { isoCode: 'CA', name: 'California' } },
  { country: 'US', state: { isoCode: 'CO', name: 'Colorado' } },
  { country: 'US', state: { isoCode: 'CT', name: 'Connecticut' } },
  { country: 'US', state: { isoCode: 'DE', name: 'Delaware' } },
  { country: 'US', state: { isoCode: 'DC', name: 'District of Columbia' } },
  { country: 'US', state: { isoCode: 'FL', name: 'Florida' } },
  { country: 'US', state: { isoCode: 'GA', name: 'Georgia' } },
  { country: 'US', state: { isoCode: 'HI', name: 'Hawaii' } },
  { country: 'US', state: { isoCode: 'ID', name: 'Idaho' } },
  { country: 'US', state: { isoCode: 'IL', name: 'Illinois' } },
  { country: 'US', state: { isoCode: 'IN', name: 'Indiana' } },
  { country: 'US', state: { isoCode: 'IA', name: 'Iowa' } },
  { country: 'US', state: { isoCode: 'KS', name: 'Kansas' } },
  { country: 'US', state: { isoCode: 'KY', name: 'Kentucky' } },
  { country: 'US', state: { isoCode: 'LA', name: 'Louisiana' } },
  { country: 'US', state: { isoCode: 'ME', name: 'Maine' } },
  { country: 'US', state: { isoCode: 'MD', name: 'Maryland' } },
  { country: 'US', state: { isoCode: 'MA', name: 'Massachusetts' } },
  { country: 'US', state: { isoCode: 'MI', name: 'Michigan' } },
  { country: 'US', state: { isoCode: 'MN', name: 'Minnesota' } },
  { country: 'US', state: { isoCode: 'MS', name: 'Mississippi' } },
  { country: 'US', state: { isoCode: 'MO', name: 'Missouri' } },
  { country: 'US', state: { isoCode: 'MT', name: 'Montana' } },
  { country: 'US', state: { isoCode: 'NE', name: 'Nebraska' } },
  { country: 'US', state: { isoCode: 'NV', name: 'Nevada' } },
  { country: 'US', state: { isoCode: 'NH', name: 'New Hampshire' } },
  { country: 'US', state: { isoCode: 'NJ', name: 'New Jersey' } },
  { country: 'US', state: { isoCode: 'NM', name: 'New Mexico' } },
  { country: 'US', state: { isoCode: 'NY', name: 'New York' } },
  { country: 'US', state: { isoCode: 'NC', name: 'North Carolina' } },
  { country: 'US', state: { isoCode: 'ND', name: 'North Dakota' } },
  { country: 'US', state: { isoCode: 'OH', name: 'Ohio' } },
  { country: 'US', state: { isoCode: 'OK', name: 'Oklahoma' } },
  { country: 'US', state: { isoCode: 'OR', name: 'Oregon' } },
  { country: 'US', state: { isoCode: 'PA', name: 'Pennsylvania' } },
  { country: 'US', state: { isoCode: 'PR', name: 'Puerto Rico' } },
  { country: 'US', state: { isoCode: 'RI', name: 'Rhode Island' } },
  { country: 'US', state: { isoCode: 'SC', name: 'South Carolina' } },
  { country: 'US', state: { isoCode: 'SD', name: 'South Dakota' } },
  { country: 'US', state: { isoCode: 'TN', name: 'Tennessee' } },
  { country: 'US', state: { isoCode: 'TX', name: 'Texas' } },
  { country: 'US', state: { isoCode: 'UT', name: 'Utah' } },
  { country: 'US', state: { isoCode: 'VT', name: 'Vermont' } },
  { country: 'US', state: { isoCode: 'VA', name: 'Virginia' } },
  { country: 'US', state: { isoCode: 'WA', name: 'Washington' } },
  { country: 'US', state: { isoCode: 'WV', name: 'West Virginia' } },
  { country: 'US', state: { isoCode: 'WI', name: 'Wisconsin' } },
  { country: 'US', state: { isoCode: 'WY', name: 'Wyoming' } },
  { country: 'CA', state: { isoCode: 'AB', name: 'Alberta' } },
  { country: 'CA', state: { isoCode: 'BC', name: 'British Columbia' } },
  { country: 'CA', state: { isoCode: 'MB', name: 'Manitoba' } },
  { country: 'CA', state: { isoCode: 'NB', name: 'New Brunswick' } },
  {
    country: 'CA',
    state: { isoCode: 'NL', name: 'Newfoundland and Labrador' },
  },
  { country: 'CA', state: { isoCode: 'NT', name: 'Northwest Territories' } },
  { country: 'CA', state: { isoCode: 'NS', name: 'Nova Scotia' } },
  { country: 'CA', state: { isoCode: 'NU', name: 'Nunavut' } },
  { country: 'CA', state: { isoCode: 'ON', name: 'Ontario' } },
  { country: 'CA', state: { isoCode: 'PE', name: 'Prince Edward Island' } },
  { country: 'CA', state: { isoCode: 'QC', name: 'Quebec' } },
  { country: 'CA', state: { isoCode: 'SK', name: 'Saskatchewan' } },
  { country: 'CA', state: { isoCode: 'YT', name: 'Yukon' } },
]
