import type { Database as SupabaseDatabase } from '@/supabase/types'

import { createBrowserSupabaseClient } from '@supabase/auth-helpers-nextjs'
import { useSupabaseClient } from '@supabase/auth-helpers-react'

export type { SupabaseDatabase }

export const supabaseClient = createBrowserSupabaseClient<SupabaseDatabase>({
  cookieOptions: {
    maxAge: 31536000, // one year
  },
})

export function useTypedSupabaseClient() {
  return useSupabaseClient<SupabaseDatabase>()
}
