import type { StyleFunctionProps } from '@chakra-ui/theme-tools'

import { mode, transparentize } from '@chakra-ui/theme-tools'

const variants = {
  'filled': (props: StyleFunctionProps) => {
    if (props.colorScheme === 'gray') {
      return {
        field: {
          _focus: {
            bg: mode('white', 'gray.800')(props),
            borderColor: 'accent',
          },
          _hover: {
            bg: mode('white', 'gray.700')(props),
            borderColor: mode('gray.200', 'gray.700')(props),
          },
          bg: mode('white', 'gray.800')(props),
        },
      }
    }
    return {
      field: {
        _focus: {
          bg: 'bg-accent-subtle',
          borderColor: 'brand.300',
        },
        _hover: {
          bg: 'bg-accent-subtle',
          borderColor: 'brand.400',
        },
        _placeholder: {
          color: 'on-accent',
        },
        bg: 'bg-accent-subtle',
        color: 'on-accent',
      },
    }
  },
  'outline': (props: StyleFunctionProps) => ({
    addon: {
      bg: mode('gray.50', 'gray.700')(props),
      borderRadius: 'lg',
    },
    field: {
      _focus: {
        borderColor: mode('brand.500', 'brand.200')(props),
        boxShadow: mode(
          `0px 0px 0px 1px ${transparentize(`brand.500`, 1.0)(props.theme)}`,
          `0px 0px 0px 1px ${transparentize(`brand.200`, 1.0)(props.theme)}`,
        )(props),
      },
      _hover: { borderColor: mode('gray.300', 'gray.600')(props) },
      bg: mode('white', 'gray.800')(props),
      borderRadius: 'lg',
    },
  }),
  'outline-on-accent': (props: StyleFunctionProps) => ({
    field: {
      _focus: {
        borderColor: 'brand.200',
        boxShadow: `0px 0px 0px 1px ${transparentize(
          `brand.200`,
          1.0,
        )(props.theme)}`,
      },
      _hover: {
        borderColor: 'brand.100',
      },
      _placeholder: {
        color: 'gray.500',
      },
      bg: 'white',
      borderColor: 'brand.50',
      borderRadius: 'lg',
      borderWidth: '1px',
      color: 'gray.900',
    },
  }),
}

const sizes = {
  lg: {
    field: {
      borderRadius: 'lg',
      fontSize: 'md',
    },
  },
}

const input = {
  defaultProps: {
    colorScheme: 'gray',
  },
  sizes,
  variants,
}
export default input
