import type { JSONValue } from 'superjson/dist/types'

import { z } from 'zod'

export type IdNameRecord = z.infer<typeof idNameRecordSchema>
export const idNameRecordSchema = z.object({ id: z.string(), name: z.string() })

export function getIdNameRecord(value: JSONValue) {
  const parsed = idNameRecordSchema.safeParse(value)
  if (parsed.success) {
    return parsed.data
  }
  return null
}
